// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cart-total-reward-subtotal {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 27px 0 !important;
}

.cart-total-reward-subtotal:after {
  content: "";
  position: absolute;
  bottom: -14px;
  left: 0;
  height: 1px;
  width: 100%;
  background-color: #e6e6e6;
}

.side-cart-total-reward-subtotal {
  border-top: 1px solid #e6e6e6;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.previewCartTotals-points {
  display: flex;
  justify-content: space-between;
}

.reward-points-table-side-cart {
  padding: 10px;
  border-radius: 5px;
}

.reward-points-table-side-cart:has(.cart--totals-title) {
  border: 1px solid black;
}

.previewCartTotals-points-label {
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  color: #232323;
}

.previewCartTotals-points-value {
  text-align: right;
  font-weight: 600;
  font-size: 15px;
  color: #232323;
}

.cart--totals-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,8BAA8B;EAC9B,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,OAAO;EACP,WAAW;EACX,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,6BAA6B;EAC7B,mBAAmB;EACnB,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".cart-total-reward-subtotal {\r\n  display: flex !important;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  position: relative;\r\n  margin: 27px 0 !important;\r\n}\r\n\r\n.cart-total-reward-subtotal:after {\r\n  content: \"\";\r\n  position: absolute;\r\n  bottom: -14px;\r\n  left: 0;\r\n  height: 1px;\r\n  width: 100%;\r\n  background-color: #e6e6e6;\r\n}\r\n\r\n.side-cart-total-reward-subtotal {\r\n  border-top: 1px solid #e6e6e6;\r\n  align-items: center;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  margin-top: 10px;\r\n}\r\n\r\n.previewCartTotals-points {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}\r\n\r\n.reward-points-table-side-cart {\r\n  padding: 10px;\r\n  border-radius: 5px;\r\n}\r\n\r\n.reward-points-table-side-cart:has(.cart--totals-title) {\r\n  border: 1px solid black;\r\n}\r\n\r\n.previewCartTotals-points-label {\r\n  text-align: left;\r\n  font-weight: 600;\r\n  font-size: 14px;\r\n  color: #232323;\r\n}\r\n\r\n.previewCartTotals-points-value {\r\n  text-align: right;\r\n  font-weight: 600;\r\n  font-size: 15px;\r\n  color: #232323;\r\n}\r\n\r\n.cart--totals-title {\r\n  font-size: 16px;\r\n  font-weight: 700;\r\n  margin-bottom: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
