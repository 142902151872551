import React, { useEffect, useState } from 'react'
import { ApiCall } from '../helper/axios';
import { createLogDB, getCookie, setCookie } from '../helper/commonFunction';
import { config_variable } from '../helper/commonApi';
import '../App.css'

const FrontRewardCart = () => {
    const [totalPoints, setTotalPoints] = useState(0);

    const generateToken = async (data, disocuntCode, e, payload) => {
        const res = await ApiCall('POST', '/generate-token', { shop: config_variable.shop_name })
        if (res.data.status === 'success' && res.data.statusCode === 200) {
            const { token } = res?.data?.data;
            const expirationHours = 24;
            setCookie("access_token", token, expirationHours);
            if (disocuntCode) {
                verifyVoucher(token, data, disocuntCode, e);
            }
            if (payload) {
                const log_payload = {
                    table_name: 'general_log',
                    type: "highRewardPoints===>>>>Cartpage>>>>>generateToken",
                    log: {
                        product_id: JSON.stringify(payload),
                        customer_card_number: customer_card_number ? customer_card_number : "card not found",
                        cid: window?.__st?.cid ? window?.__st?.cid : 'cid not found'
                    }
                }
                await createLogDB(getCookie('access_token'), log_payload)
                return await highRewardPoints(payload, token);
            }
        }
    }

    const updateCartAttributes = async (cartData, body) => {
        let quantity = null;
        if (body && body.includes("quantity=")) {
            quantity = body.split('quantity=')[1].split('&')[0]
        }
        if (parseInt(quantity) === 0) {
            await fetchProductData();
        }
        await changeCartData();
    }

    const cartAttributesJSON = async (cartData) => {
        let obj = {};
        let reward_obj = { products: {} };
        let product_ids = [];
        if (cartData.items?.length) {
            cartData.items.forEach(({ discounted_price, quantity, product_id }) => {
                const original_price = parseInt((discounted_price / 100) * quantity);
                product_ids.push({product_id, quantity, original_price})
                if (reward_obj.products[product_id] || parseInt(reward_obj.products[product_id]) === 0) {
                    reward_obj.products[product_id] += original_price;
                } else {
                    reward_obj.products[product_id] = original_price;
                }
                
            });
            const log_payload = {
                table_name: 'general_log',
                type: "highRewardPoints===>>>>>CartPage===>>>>cartAttributesJSON",
                log: {
                    product_id: JSON.stringify(product_ids),
                    customer_card_number: customer_card_number ? customer_card_number : "card not found",
                    cid: window?.__st?.cid ? window?.__st?.cid : 'cid not found'
                }
            }
            await createLogDB(getCookie('access_token'), log_payload)
            let j = 0;
            const rewardItemPoints = await highRewardPoints(reward_obj, getCookie('access_token'));
            if (rewardItemPoints && Object.keys(rewardItemPoints).length) {
                while (j < cartData.items.length) {
                    const { id, product_title, product_id } = cartData.items[j];
                    const original_price = rewardItemPoints[product_id];
                    const points = original_price;
                    /* if (obj[product_title]) {
                        obj[product_title].points += parseInt(points);
                    } else {
                        obj[product_title] = { title: product_title, points: parseInt(points), id, product_id };
                    } */
                    obj[product_title] = { title: product_title, points: points, id, product_id };
                    j++;
                }
            }
        }
        return Object.values(obj);
    }

    const cartPageHTML = async (cartData) => {
        await cartAttributesJSON(cartData).then((cartContent) => {
            const script = document.querySelector('.cart-content-item.cart-total');
            if (cartContent.length && script) {
                let newElement = document.createElement('ul');
                newElement.classList.add('reward-points-table', 'cart-totals', 'list-unstyled');
                if (document.querySelector('.reward-points-table')) {
                    newElement = document.querySelector('.reward-points-table');
                }
                newElement.innerHTML = '';
                newElement.innerHTML = `<div class="cart--totals-title">
                    Reward Points
                </div>`;
                let points = 0;
                cartContent.forEach((item) => {
                    const countPoints = item.points ? item.points : 0;
                    points += countPoints;
                    newElement.innerHTML += `<li data-product="${item.product_id}" class="cart-total cart-total-reward-subtotal" data-variant="${item.id}">
                    <div class="cart-total-label">
                      <span class="text">${item.title}</span>
                    </div>
                    <div class="cart-total-value">
                      <span class="text">${countPoints}</span>
                    </div>
                  </li>`;
                })
                newElement.innerHTML += `<li class="cart-total cart-total-reward-subtotal">
                <div class="cart-total-label">
                  <span class="text">Total reward points</span>
                </div>
                <div class="cart-total-value">
                  <span class="text">${points}</span>
                </div>
              </li>`
                script.insertBefore(newElement, script.firstChild);
            }
        });
    }


    const fetchProductData = async (token) => {
        const cartData = await fetchCartData();
        let value = 0;
        // await cartPageHTML(cartData);
        let obj = {};
        let delObj = {};
        await cartAttributesJSON(cartData).then(async (cartAttributesData) => {
            const keyss = Object.keys(cartData.attributes);
            keyss.forEach(key => {
                delObj[key] = null;
            });

            const updateCartPayloads = JSON.stringify({
                attributes: delObj
            });

            await updateCartData(updateCartPayloads).then(async () => {
                if (cartAttributesData.length) {
                    cartAttributesData.forEach((item) => {
                        obj[item.title] = item.points
                    })
                }
                const keys = Object.keys(cartData.attributes);

                keys.forEach(key => {
                    if (!obj.hasOwnProperty(key)) {
                        obj[key] = null;
                    }
                });

                const log_payload = {
                    table_name: 'general_log',
                    type: "customer_card_number===>>>>>>>>>",
                    log: {
                        productData: obj,
                        customer_card_number: customer_card_number,
                        cid: window?.__st?.cid ? window?.__st?.cid : ''
                    }
                }
                if (!customer_card_number && !window?.__st?.cid) {
                    log_payload.type = "======both paramenter not found===>>>>>>>>>";
                    log_payload.log = {
                        productData: obj
                    }
                }
                await createLogDB(getCookie('access_token'), log_payload)

                // eslint-disable-next-line no-undef
                const regoutletData = customer_card_number ? await getOutletData(getCookie('access_token')) : window?.__st?.cid ? await getOutletData(getCookie('access_token'), window?.__st?.cid) : '';

                log_payload.type = "======regoutletData===>>>>>>>>>";
                log_payload.log = {
                    customer_card_number: customer_card_number ? customer_card_number : "",
                    cid: window?.__st?.cid ? window?.__st?.cid : '',
                    regoutletData: regoutletData,
                }

                await createLogDB(getCookie('access_token'), log_payload);

                if (Object.keys(obj).length && Object.keys(obj)[0] !== '// RegOutlet') {
                    obj['// RegOutlet'] = regoutletData;
                } else {
                    obj['// RegOutlet'] = null;
                }

                const updateCartPayload = JSON.stringify({
                    attributes: obj
                });

                await updateCartData(updateCartPayload);
            });
        });

        cartData.items.map((datas) => {
            if (Object.keys(datas.properties).length) {
                if (datas.properties['Reward Point']) {
                    value += (datas.properties['Reward Point']);
                }
            }
        })
        if (document.querySelector('.total-cart-reward-points span')) {
            document.querySelector('.total-cart-reward-points span').innerHTML = parseInt(value);
        }
        setTotalPoints(value);
    }

    const highRewardPoints = async (data, token) => {
        if (token) {
            const res = await ApiCall('POST', '/high_reward_point', data, { authentication: token }, '1');
            if (res.data.status === 'SUCCESS' && res.status === 200) {
                const rewardPoints = res.data.data;
                return rewardPoints;
            }
        } else {
            generateToken(0, '', '', data);
        }
    }

    const changeCartData = async () => {
        const cartData = await fetchCartData();
        // await cartPageHTML(cartData);
        let obj = {};
        let delObj = {};
        await cartAttributesJSON(cartData).then(async (cartAttributesData) => {
            const keyss = Object.keys(cartData.attributes);
            keyss.forEach(key => {
                delObj[key] = null;
            });

            const updateCartPayloads = JSON.stringify({
                attributes: delObj
            });

            await updateCartData(updateCartPayloads).then(async (data) => {
                if (cartAttributesData.length) {
                    cartAttributesData.forEach((item) => {
                        obj[item.title] = item.points
                    })
                }
                const keys = Object.keys(cartData.attributes);

                keys.forEach(key => {
                    if (!obj.hasOwnProperty(key)) {
                        obj[key] = null;
                    }
                });

                const log_payload = {
                    table_name: 'general_log',
                    type: "customer_card_number===>>>>>>>>>",
                    log: {
                        productData: obj,
                        customer_card_number: customer_card_number,
                        cid: window?.__st?.cid ? window?.__st?.cid : ''
                    }
                }
                if (!customer_card_number && !window?.__st?.cid) {
                    log_payload.type = "======both paramenter not found===>>>>>>>>>";
                    log_payload.log = {
                        productData: obj
                    }
                }
                await createLogDB(getCookie('access_token'), log_payload)

                // eslint-disable-next-line no-undef
                const regoutletData = customer_card_number ? await getOutletData(getCookie('access_token')) : window?.__st?.cid ? await getOutletData(getCookie('access_token'), window?.__st?.cid) : '';

                log_payload.type = "======regoutletData===>>>>>>>>>";
                log_payload.log = {
                    customer_card_number: customer_card_number ? customer_card_number : "",
                    cid: window?.__st?.cid ? window?.__st?.cid : '',
                    regoutletData: regoutletData,
                }

                await createLogDB(getCookie('access_token'), log_payload);
    
                if (Object.keys(obj).length && Object.keys(obj)[0] !== '// RegOutlet') {
                    obj['// RegOutlet'] = regoutletData;
                } else {
                    obj['// RegOutlet'] = null;
                }

                const updateCartPayload = JSON.stringify({
                    attributes: obj
                });

                await updateCartData(updateCartPayload);
                if (document.querySelectorAll([".btn_cart_cls, #cart-checkout"]).length) {
                    document.querySelector([".btn_cart_cls, #cart-checkout"]).disabled = false;
                }
            });
        });
    }

    const getOutletData = async (access_token = '', cid) => {
        if (access_token) {
            // eslint-disable-next-line no-undef
            const url = customer_card_number ? `/get_outlet?cardNo=${customer_card_number}` : cid ? `/get_outlet?cardNo=''&cid=${cid}` : '';
            const res = await ApiCall('GET', url, {}, { authentication: access_token }, '1');
            if (res.data.status === 'SUCCESS' && res.status === 200) {
                const regoutletData = res.data.data.RegOutlet;
                return regoutletData;
            } else {
                return '';
            }
        } else {
            generateToken(0);
        }
    }

    const fetchCartData = async () => {
        let cartData = null;
        await fetch(`/cart.json`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                cartData = data
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
        return cartData;
    }

    const updateCartData = async (data, shouldUpdate) => {
        await fetch('/cart/update.js', {
            method: "POST",
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json;'
            },
            body: data
        }).then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        }).then(async (response) => {
            if (shouldUpdate) {
                await window.updateCartData(response, '1');
                const cartData = await fetchCartData();
                // await cartPageHTML(cartData);
            }
        });
    }

    const OriginalXMLHttpRequest = window.XMLHttpRequest;

    window.XMLHttpRequest = new Proxy(OriginalXMLHttpRequest, {
        construct(target, args) {
            // Create a new XMLHttpRequest instance
            const xhr = new target(...args);
            let body = '';
            const sendReq = XMLHttpRequest.prototype.send;
            XMLHttpRequest.prototype.send = function (arg) {
                body = arg
                sendReq.call(this, arg);
            }
            // Set up the event listener for readystatechange only if it hasn't been set up before
            if (!xhr.hasOwnProperty('readystatechangeListener')) {
                xhr.addEventListener('readystatechange', async function () {
                    const ajaxUrl = xhr._url || xhr.responseURL;
                    if (document.querySelectorAll([".btn_cart_cls, #cart-checkout"]).length && ajaxUrl && (ajaxUrl.includes('/cart/change.js') || ajaxUrl.includes('/cart/add.js'))) {
                        document.querySelector([".btn_cart_cls, #cart-checkout"]).disabled = true;
                    }
                    if (xhr.readyState === 4 && xhr.status === 200 && ajaxUrl) {
                        if (ajaxUrl && ajaxUrl.includes('/cart/change.js')) {
                            if (xhr.response) {
                                if (Object.keys(JSON.parse(xhr.response)).length) {
                                    await updateCartAttributes(JSON.parse(xhr.response), body);
                                }
                            }
                        }
                        if (ajaxUrl && ajaxUrl.includes('/cart/add.js')) {
                            await changeCartData(JSON.parse(xhr.response));
                        }
                    } else if (ajaxUrl && (ajaxUrl.includes('/cart/change.js') || ajaxUrl.includes('/cart/add.js'))) {
                        setTimeout(() => {
                            if (document.querySelectorAll([".btn_cart_cls, #cart-checkout"]).length) {
                                document.querySelector([".btn_cart_cls, #cart-checkout"]).disabled = false;
                            }
                        }, 5000);
                    }
                }, false);
                // Flag to indicate that the event listener has been set up
                xhr.readystatechangeListener = true;
            }

            return xhr;
        }
    });

    window.fetch = new Proxy(window.fetch, {
        apply(target, that, args) {
            try {
                let ajaxUrl = '';
                if (args[0] instanceof URL) {
                    ajaxUrl = args[0].href;
                } else if (args[0] instanceof Request) {
                    ajaxUrl = args[0].url;
                } else {
                    ajaxUrl = args[0] || '';
                }
                if (ajaxUrl && ajaxUrl.includes('/cart/add.js')) {
                    const result = target.apply(that, args);
                    result.then((responce) => {
                        return responce.json();
                    }).then(async (responce) => {
                        await changeCartData(responce);
                    })
                }
            } catch (error) {
                console.error('Error in proxy:', error);
            }

            // Ensure to return the result of the original fetch function
            return target.apply(that, args);
        },
    });

    useEffect(() => {
        fetchProductData();

        let checkoutBtn = document.querySelector(".button-checkout");
        if (checkoutBtn) {
            checkoutBtn.addEventListener("click", async function (e) {
                checkoutBtn.classList.add('is-loading');
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                let obj = {};
                let delObj = {};
                const cartData = await fetchCartData();
                await cartAttributesJSON(cartData).then(async (cartAttributesData) => {
                    const keyss = Object.keys(cartData.attributes);
                    keyss.forEach(key => {
                        delObj[key] = null;
                    });

                    const updateCartPayloads = JSON.stringify({
                        attributes: delObj
                    });

                    await updateCartData(updateCartPayloads).then(async () => {
                        if (cartAttributesData.length) {
                            cartAttributesData.forEach((item) => {
                                obj[item.title] = item.points 
                            })
                        }
                        const keys = Object.keys(cartData.attributes);
        
                        keys.forEach(key => {
                            if (!obj.hasOwnProperty(key)) {
                                obj[key] = null;
                            }
                        });

                        const log_payload = {
                            table_name: 'general_log',
                            type: "customer_card_number===>>>>>>>>>",
                            log: {
                                productData: obj,
                                customer_card_number: customer_card_number,
                                cid: window?.__st?.cid ? window?.__st?.cid : ''
                            }
                        }
                        if (!customer_card_number && !window?.__st?.cid) {
                            log_payload.type = "======both paramenter not found===>>>>>>>>>";
                            log_payload.log = {
                                productData: obj
                            }
                        }
                        await createLogDB(getCookie('access_token'), log_payload)

                        // eslint-disable-next-line no-undef
                        const regoutletData = customer_card_number ? await getOutletData(getCookie('access_token')) : window?.__st?.cid ? await getOutletData(getCookie('access_token'), window?.__st?.cid) : '';

                        log_payload.type = "======regoutletData===>>>>>>>>>";
                        log_payload.log = {
                            customer_card_number: customer_card_number ? customer_card_number : "",
                            cid: window?.__st?.cid ? window?.__st?.cid : '',
                            regoutletData: regoutletData
                        }

                        await createLogDB(getCookie('access_token'), log_payload);
            
                        if (Object.keys(obj).length && Object.keys(obj)[0] !== '// RegOutlet') {
                            obj['// RegOutlet'] = regoutletData;
                        } else {
                            obj['// RegOutlet'] = null;
                        }
        
                        const updateCartPayload = JSON.stringify({
                            attributes: obj
                        });
                
                        await updateCartData(updateCartPayload);
                    });
                });
                const disocuntCode = getCookie('rewardVoucherCode');
                if (disocuntCode) {
                    // eslint-disable-next-line no-undef
                    const customerCardID = customer_card_number
                    // eslint-disable-next-line no-undef
                    const totalCartAmount = total_cart_amount
                    const data = {
                        customer_id: window?.__st?.cid,
                        voucher_code: disocuntCode,
                        cardNo: customerCardID,
                        amount: totalCartAmount ? (totalCartAmount / 100) : 0
                    }

                    if (getCookie('access_token')) {
                        verifyVoucher(getCookie('access_token'), data, disocuntCode, e);
                    } else {
                        generateToken(data, disocuntCode, e);
                    }
                } else {
                    window.handleCartCheckoutBtn(e);
                }
            }, true);
        }
    }, []);

    const verifyVoucher = async (token, data, disocuntCode, event) => {
        const res = await ApiCall('POST', '/verifyVoucher', data, { authentication: token }, '1');
        if (res.data.status === 'SUCCESS' && res.status === 200) {
            const response = res.data.data;
            if (response && response.verifed) {
                await fetch(`/discount/${disocuntCode}`).then((res) => {
                    return res.text();
                }).then(() => {
                    window.handleCartCheckoutBtn(event);
                }).catch((err) => {
                    window.handleCartCheckoutBtn(event);
                })
            } else {
                window.handleCartCheckoutBtn(event);
            }
        } else {
            window.handleCartCheckoutBtn(event);
        }
    }

    /* return (
        totalPoints ? <>
            <div class="cart-total-label">
                <span class="text">Points Earned</span>
            </div>
            <div class="cart-total-value">
                <span class="text">{totalPoints}</span>
            </div>
        </> : <></>
    ) */
}

export default FrontRewardCart