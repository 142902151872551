import React, { useEffect, useState } from "react";
import { ApiCall } from "../helper/axios";
import { config_variable } from "../helper/commonApi";
import { getCookie, setCookie } from "../helper/commonFunction";

const ThankyouPage = () => {
  const [rewardPoints, setRewardPoints] = useState(0);

  const generateToken = async () => {
    const res = await ApiCall('POST', '/generate-token', { shop: config_variable.shop_name })
    if (res.data.status === 'success' && res.data.statusCode === 200) {
        const { token } = res?.data?.data;
        const expirationHours = 24;
        setCookie("access_token", token, expirationHours);
        getOrderDetail(token, window.Shopify.checkout.order_id);
    }
  }


  const getOrderDetail = async (token, orderId) => {
    if (token) {
      const data = {
        orderId: orderId
      }
      const res = await ApiCall('POST', '/get_order_detail', data, { authentication: token }, '1');
      if (res.data.status === 'SUCCESS' && res.status === 200) {
          const rewardPoints = res.data.data;
          if (rewardPoints && rewardPoints?.length) {
            let rewards = 0;
            rewardPoints.forEach((item) => {
              if (item.name !== '// RegOutlet') {
                rewards += parseInt(item.value);
              }
            });
            setRewardPoints(rewards);
          }
      }
    } else {
        generateToken();
    }
  }

  useEffect(() => {
    if (window.Shopify.checkout.order_id) {
      getOrderDetail(getCookie('access_token'), window.Shopify.checkout.order_id);
    }
  }, []);

  return (
    rewardPoints || rewardPoints === 0 ? (
        <>
          <th class="total-line__name payment-due-label" scope="row">
            <span class="payment-due-label__total">Total reward point</span>
          </th>
          <td class="total-line__price payment-due">
            <span class="payment-due__price skeleton-while-loading--lg">
              {rewardPoints}
            </span>
          </td>
        </>
      ) : (
        <></>
      )
  );
};

export default ThankyouPage;
