import React, { useCallback, useEffect, useState } from 'react';
import { ApiCall } from '../helper/axios';
import { config_variable } from '../helper/commonApi';
import { createLogDB, getCookie, setCookie } from '../helper/commonFunction';
import axios from 'axios';

const AaPharmacyFront = ({ pageurl }) => {
    const [productData, setProductData] = useState({});
    const [rewardPoints, setRewardPoints] = useState('');

    const generateToken = async (flag, data) => {
        const res = await ApiCall('POST', '/generate-token', { shop: config_variable.shop_name })
        if (res.data.status === 'success' && res.data.statusCode === 200) {
            const { token } = res?.data?.data;
            const expirationHours = 24;
            setCookie("access_token", token, expirationHours);
            if (data) { // data get for recall high_reward_points api cal return to change cart data
                const log_payload = {
                    table_name: 'general_log',
                    type: "highRewardPoints===>>>>>>>>>generateToken",
                    log: {
                        product_id: JSON.stringify(data),
                        customer_card_number: customer_card_number ? customer_card_number : "card not found",
                        cid: window?.__st?.cid ? window?.__st?.cid : 'cid not found'
                    }
                }
                await createLogDB(getCookie('access_token'), log_payload)
                return await highRewardPoints(data, token);
            }
            if (flag) {
                fetchProductData(token);
            }
        }
    }

    const fetchProductData = async (token) => {
        const url = `${window.location.origin}/products/${window.location.pathname.split('products/')[1]}.json`;
        try {
            const response = await axios.get(url);
            if (response.status === 200 && Object.keys(response.data).length) {
                if (Object.keys(response.data.product).length) {
                    setProductData(response.data.product);
                    getRewardPoints(response.data.product, token);
                }
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    const addPropertiesCartBuyNow = async (quantity) => {
        /* clear the cart data before buy now buttton click */
        try {
            await fetch(`/cart/clear.js`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
            }).then((response) => {
                return response.json();
            }).then((response) => {
                console.log('response==', response);
            }).catch((error) => {
                throw new Error(`HTTP error! Status: ${error}`);
            })

            /* then we get reward points of current product */
            let varientPrice = parseFloat(productData.variants[0].price);
            let varientID = productData.variants[0].id
            if (productData.variants.length > 1) {
                if (document.querySelector("legend.form__label")) {
                    if (document.querySelector('fieldset input:checked')) {
                        let selectedVarient = productData.variants.filter((item) => item.title === document.querySelector('fieldset input:checked').value)
                        varientPrice = parseFloat(selectedVarient[0].price);
                        varientID = parseFloat(selectedVarient[0].id);
                    }
                }
            }

            const log_payload = {
                table_name: 'general_log',
                type: "highRewardPoints===>>>>>>>>>addPropertiesCartBuyNow",
                log: {
                    product_id: productData.id,
                    product_qty: quantity,
                    product_price: varientPrice,
                    varientID: varientID,
                    customer_card_number: customer_card_number ? customer_card_number : "card not found",
                    cid: window?.__st?.cid ? window?.__st?.cid : 'cid not found'
                }
            }
            await createLogDB(getCookie('access_token'), log_payload)

            const datas = { 
                products: { [`${productData.id}`]: varientPrice }
            }
            let rewardItemPoints = await highRewardPoints(datas, getCookie('access_token'));
            if (rewardItemPoints && Object.keys(rewardItemPoints).length && rewardItemPoints[productData.id]) {
                rewardItemPoints = rewardPoints[productData.id];
                setRewardPoints(parseInt(rewardPoints[productData.id]));
            } else {
                rewardItemPoints = 0;
            }
            if (document.querySelector('.product-form__buttons .reward_addto_cart[name="add"]') && pageurl === 'product-page') {
                if (document.querySelector('.product-form__buttons .reward_addto_cart[name="add"]').innerText !== 'Sold Out') {
                    document.querySelector('.product-form__buttons .reward_addto_cart[name="add"]').disabled = false;
                }
                if (document.querySelector('.quantity__input') && document.querySelector('.btn-quantity.minus') && document.querySelector('.btn-quantity.plus')) {
                    document.querySelector('.quantity__input').disabled = false;
                    document.querySelector('.btn-quantity.minus').disabled = false;
                    document.querySelector('.btn-quantity.plus').disabled = false;
                }
            }

            /* add cart data and properties to cart and then redirect to checkout page */
            let data = {}
            data['form_type'] = 'product';
            data['utf8'] = '✓';
            data['quantity'] = quantity;
            data['id'] = varientID;
            data['product-id'] = productData.id;
            data['attributes'] = {
                [`${productData.title}`]: parseInt(parseFloat(rewardItemPoints) * parseInt(quantity))
            }
            const res = await axios.post('/cart/add.js', data, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json;'
                }
            })
            if (res.status === 200 && res.data) {
                if (Object.keys(res.data).length) {
                    const disocuntCode = getCookie('rewardVoucherCode');
                    if (disocuntCode) {
                        await fetch(`/discount/${disocuntCode}`).then((res) => {
                            return res.text();
                        }).then((res) => {
                            window.location.href = `https://aapharmacy.com.my/checkout`;
                        }).catch((err) => {
                            window.location.href = `https://aapharmacy.com.my/checkout`;
                        })
                    } else {
                        window.location.href = `https://aapharmacy.com.my/checkout`;
                    }
                } else {
                    window.location.href = `https://aapharmacy.com.my/checkout`;
                }
            } else {
                window.location.href = `https://aapharmacy.com.my/checkout`;
            }
        } catch (error) {
            console.log('somethig went wrong!', error);
            window.location.href = `https://aapharmacy.com.my/checkout`;
        }
    }

    const getRewardPoints = async (product, token) => {
        let varientPrice = parseFloat(product.variants[0].price);
        let varientID = product.variants[0].id
        if (product.variants.length > 1) {
            if (document.querySelector("legend.form__label")) {
                if (document.querySelector('fieldset input:checked')) {
                    let selectedVarient = product.variants.filter((item) => item.title === document.querySelector('fieldset input:checked').value)
                    varientID = parseFloat(selectedVarient[0].id);
                    varientPrice = parseFloat(selectedVarient[0].price);
                }
            }
        }

        const log_payload = {
            table_name: 'general_log',
            type: "highRewardPoints===>>>>>>>>>getRewardPoints",
            log: {
                product_id: product.id,
                product_qty: 1,
                product_price: varientPrice,
                varientID: varientID,
                customer_card_number: customer_card_number ? customer_card_number : "card not found",
                cid: window?.__st?.cid ? window?.__st?.cid : 'cid not found'
            }
        }
        await createLogDB(getCookie('access_token'), log_payload)

        const data = { products: {
            [`${product.id}`]: varientPrice
        }}
        const rewardPoints = await highRewardPoints(data, token);
        if (rewardPoints && Object.keys(rewardPoints).length && rewardPoints[product.id]) {
            setRewardPoints(parseInt(rewardPoints[product.id]));
        }
        if (document.querySelector('.reward_addto_cart[name="add"]') && pageurl === 'product-page') {
            if (document.querySelector('.reward_addto_cart[name="add"]').innerText !== 'Sold Out') {
                document.querySelector('.reward_addto_cart[name="add"]').disabled = false;
            }
            if (document.querySelector('.quantity__input') && document.querySelector('.btn-quantity.minus') && document.querySelector('.btn-quantity.plus')) {
                document.querySelector('.quantity__input').disabled = false;
                document.querySelector('.btn-quantity.minus').disabled = false;
                document.querySelector('.btn-quantity.plus').disabled = false;
            }

            if (document.querySelectorAll('.card .reward_addto_cart[name="add"]:disabled').length) {
                window.addEventListener("load", () => {
                    document.querySelectorAll('.card .reward_addto_cart[name="add"]:disabled').forEach((item) => {
                        item.removeAttribute('disabled');
                    })
                });
            
                window.addEventListener("scroll", () => {
                    document.querySelectorAll('.card .reward_addto_cart[name="add"]:disabled').forEach((item) => {
                        item.removeAttribute('disabled');
                    })
                });
            }
        }
    }

    const highRewardPoints = async (data, token) => {
        if (token) {
            const res = await ApiCall('POST', '/high_reward_point', data, { authentication: token }, '1');
            if (res.data.status === 'SUCCESS' && res.status === 200) {
                const rewardPoints = res.data.data;
                return rewardPoints;
            } else {
                return 0;
            }
        } else {
            generateToken(0, data);
        }
    }

    useEffect(() => {
        if (pageurl === 'product-page') {
            if (getCookie('access_token')) {
                fetchProductData(getCookie('access_token'));
            } else {
                generateToken(true);
            }
        } else if (!getCookie('access_token')) {
            generateToken(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hanldeBuyNowButtonEvent = async () => {
        let btn = document.querySelector(".shopify-payment-button__button");
        btn.addEventListener("click", function (e) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            this.classList.add('is-loading', 'button');
            const form = this.closest('form');
            const quantity = form.querySelector('input[name="quantity"]').value
            addPropertiesCartBuyNow(quantity);
        }, true);
    }

    const cartAttributesJSON = async (cartData) => {
        let obj = {};
        let reward_obj = { products: {} };
        let product_ids = [];
        if (cartData.items?.length) {
            cartData.items.forEach(({ discounted_price, quantity, product_id }) => {
                const original_price = parseInt((discounted_price / 100) * quantity);
                product_ids.push({product_id, quantity, original_price})
                if (reward_obj.products[product_id]) {
                    reward_obj.products[product_id] += original_price;
                } else {
                    reward_obj.products[product_id] = original_price;
                }
            });
            const log_payload = {
                table_name: 'general_log',
                type: "highRewardPoints===>>>>>>>>>cartAttributesJSON",
                log: {
                    product_id: JSON.stringify(product_ids),
                    customer_card_number: customer_card_number ? customer_card_number : "card not found",
                    cid: window?.__st?.cid ? window?.__st?.cid : 'cid not found'
                }
            }
            await createLogDB(getCookie('access_token'), log_payload)
            let j = 0;
            const rewardItemPoints = await highRewardPoints(reward_obj, getCookie('access_token'));
            if (rewardItemPoints && Object.keys(rewardItemPoints).length) {
                while (j < cartData.items.length) {
                    const { id, product_title, product_id } = cartData.items[j];
                    const original_price = rewardItemPoints[product_id];
                    const points = original_price;
        
                    /* if (obj[product_title]) {
                        obj[product_title].points += parseInt(points);
                    } else {
                        obj[product_title] = { title: product_title, points: parseInt(points), id, product_id };
                    } */
                    obj[product_title] = { title: product_title, points: points, id, product_id };
                    j++;
                }
            }
        }
        return Object.values(obj);
    }

    const cartPageHTML = async (cartData) => {
        await cartAttributesJSON(cartData).then((cartContent) => {
            const script = document.querySelector('#halo-cart-sidebar .previewCart-wrapper .previewCart');
            if (cartContent.length && script) {
                let newElement = document.createElement('ul');
                newElement.classList.add('reward-points-table-side-cart', 'previewCartTotalsPrice', 'list-unstyled');
                if (document.querySelector('.reward-points-table-side-cart')) {
                    newElement = document.querySelector('.reward-points-table-side-cart');
                }
                newElement.innerHTML = '';
                newElement.innerHTML = `<div class="cart--totals-title">
                    Reward Points 
                </div>`;
                let points = 0;
                cartContent.forEach((item) => {
                    const cartPoints = item.points ? item.points : 0;
                    points += cartPoints;
                    newElement.innerHTML += `<li data-variant="${item.id}" class="previewCartTotals-points" data-product="${item.product_id}">
                    <div class="previewCartTotals-points-label">    
                        ${item.title}
                    </div>
                    <div class="previewCartTotals-points-value">
                        ${cartPoints}
                    </div>
                </li>`;
                });
                newElement.innerHTML += `<li class="cart-total side-cart-total-reward-subtotal">
                    <div class="previewCartTotals-points-label">
                    <span class="text">Total reward points</span>
                    </div>
                    <div class="previewCartTotals-points-value">
                    <span class="text">${points}</span>
                    </div>
                </li>`;
                script.appendChild(newElement, script.firstChild);
            }
        });
    }

    useEffect(() => {
        if (Object.keys(productData).length) {
            function checkButtonPresence() {
                const button = document.querySelector(".shopify-payment-button__button");
                if (button) {
                    if (button.innerHTML === 'Buy it now') {
                        hanldeBuyNowButtonEvent()
                        clearInterval(intervalId);
                    } else {
                        button.disabled = true;
                    }
                }
            }

            const intervalId = setInterval(checkButtonPresence, 500);

            const timeoutId = setTimeout(() => {
                clearInterval(intervalId);
            }, 10000); // Clear the interval if button is not fountd in 10 seconds

            document.addEventListener("DOMContentLoaded", () => {
                clearTimeout(timeoutId);
            });
        }

        /* if (document.querySelectorAll('[data-cart-sidebar]').length) {
            document.querySelectorAll('[data-cart-sidebar]').forEach((item) => {
                item.addEventListener("click", async () => {
                    const cartData = await fetchCartData();
                    setTimeout(async () => {
                        await cartPageHTML(cartData)
                    }, 2000);
                });
            })
        } */

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productData]);

    const handleButtonClick = useCallback((event) => {
        if (event.target.matches('.card-quickview button')) {
            setTimeout(() => {
                hanldeBuyNowButtonEvent();
            }, 2000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleButtonClick);

        return () => {
            document.removeEventListener('click', handleButtonClick);
        };
    }, [handleButtonClick]);

    const changeCartData = async (productData, flag) => {
        const cartData = await fetchCartData();
        /* setTimeout(async () => {
            await cartPageHTML(cartData);
        }, 2000); */
        let obj = {};
        let delObj = {};
        await cartAttributesJSON(cartData).then(async (cartAttributesData) => {
            const keyss = Object.keys(cartData.attributes);
            keyss.forEach(key => {
                delObj[key] = null;
            });

            const updateCartPayloads = JSON.stringify({
                attributes: delObj
            });

            await updateCartData(updateCartPayloads).then(async () => {
                if (cartAttributesData.length) {
                    cartAttributesData.forEach((item) => {
                        obj[item.title] = item.points
                    })
                }
    
                const keys = Object.keys(cartData.attributes);
    
                keys.forEach(key => {
                    if (!obj.hasOwnProperty(key)) {
                        obj[key] = null;
                    }
                });

                const log_payload = {
                    table_name: 'general_log',
                    type: "customer_card_number===>>>>>>>>>",
                    log: {
                        productData: obj,
                        customer_card_number: customer_card_number,
                        cid: window?.__st?.cid ? window?.__st?.cid : ''
                    }
                }
                if (!customer_card_number && !window?.__st?.cid) {
                    log_payload.type = "======both paramenter not found===>>>>>>>>>";
                    log_payload.log = {
                        productData: obj
                    }
                }
                await createLogDB(getCookie('access_token'), log_payload)

                // eslint-disable-next-line no-undef
                const regoutletData = customer_card_number ? await getOutletData(getCookie('access_token')) : window?.__st?.cid ? await getOutletData(getCookie('access_token'), window?.__st?.cid) : '';

                log_payload.type = "======regoutletData===>>>>>>>>>";
                log_payload.log = {
                    customer_card_number: customer_card_number ? customer_card_number : "",
                    cid: window?.__st?.cid ? window?.__st?.cid : '',
                    regoutletData: regoutletData
                }

                await createLogDB(getCookie('access_token'), log_payload);

                if (Object.keys(obj).length && Object.keys(obj)[0] !== '// RegOutlet') {
                    obj['// RegOutlet'] = regoutletData;
                } else {
                    obj['// RegOutlet'] = null;
                }
    
                const updateCartPayload = JSON.stringify({
                    attributes: obj
                });
    
                await updateCartData(updateCartPayload);
                
                const disocuntCode = getCookie('rewardVoucherCode');
                if (disocuntCode) {
                    await fetch(`/discount/${disocuntCode}`).then((res) => {
                        return res.text();
                    }).then(async () => {
                        await updateCartProperties(cartData, flag);
                    });
                } else {
                    await updateCartProperties(cartData, flag);
                }
            });

        });
    }

    const getOutletData = async (access_token = '') => {
        if (access_token) {
            // eslint-disable-next-line no-undef
            const url = customer_card_number ? `/get_outlet?cardNo=${customer_card_number}` : cid ? `/get_outlet?cardNo=''&cid=${cid}` : '';
            const res = await ApiCall('GET', url, {}, { authentication: access_token }, '1');
            if (res.data.status === 'SUCCESS' && res.status === 200) {
                const regoutletData = res.data.data.RegOutlet;
                return regoutletData;
            } else {
                return '';
            }
        } else {
            generateToken(0);
        }
    }

    const updateCartProperties = async (cartData, flag) => {
        if (flag) {
            await window.sidebarCartCallForUpdateData(cartData, flag);
        }
    }

    const fetchCartData = async () => {
        let cartData = null;
        await fetch(`/cart.json`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                cartData = data
            })
            .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
            });
        return cartData;
    }

    const updateCartAttributes = async (cartData, body) => {
        let quantity = null;
        if (body && body.includes("quantity=")) {
            quantity = body.split('quantity=')[1].split('&')[0]
        }
        if (parseInt(quantity) === 0) {
            await fetchProductData();
        }
        await changeCartData({}, 1);
        if (document.querySelectorAll(["#cart-sidebar-checkout"]).length) {
            document.querySelector(["#cart-sidebar-checkout"]).disabled = false;
        }
    }

    const updateCartData = async (data) => {
        return await fetch('/cart/update.js', {
            method: "POST",
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json;'
            },
            body: data
        })
    }

    const OriginalXMLHttpRequest = window.XMLHttpRequest;

    window.XMLHttpRequest = new Proxy(OriginalXMLHttpRequest, {
        construct(target, args) {
            // Create a new XMLHttpRequest instance
            const xhr = new target(...args);
            let body = '';
            const sendReq = XMLHttpRequest.prototype.send;
            XMLHttpRequest.prototype.send = function (arg) {
                body = arg
                sendReq.call(this, arg);
            }
            
            // Set up the event listener for readystatechange only if it hasn't been set up before
            if (!xhr.hasOwnProperty('readystatechangeListener')) {
                xhr.addEventListener('readystatechange', async function () {
                    const ajaxUrl = xhr._url || xhr.responseURL;
                    if (document.querySelectorAll(["#cart-sidebar-checkout"]).length && ajaxUrl && ajaxUrl.includes('/cart/change.js')) {
                        document.querySelector(["#cart-sidebar-checkout"]).disabled = true;
                    }
                    if (xhr.readyState === 4 && xhr.status === 200 && ajaxUrl) {
                        if (ajaxUrl && ajaxUrl.includes('/cart/add.js')) {
                            await changeCartData(JSON.parse(xhr.response), 1); 
                        }
                        if (ajaxUrl && ajaxUrl.includes('/cart/change.js')) {
                            const previewCartElement = document.querySelector('.previewCart-wrapper');
                            if (previewCartElement) {
                                previewCartElement.classList.add('is-loading')
                                const div = document.createElement('div')
                                div.classList.add('loading-overlay', 'loading-overlay--custom')
                                div.innerHTML = `<div class="loading-overlay__spinner">
                                  <svg aria-hidden="true" focusable="false" role="presentation" class="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                  <circle class="path" fill="none" stroke-width="6" cx="33" cy="33" r="30"></circle>
                                  </svg>
                                </div>`
                                previewCartElement.appendChild(div);
                            }
                            await updateCartAttributes(JSON.parse(xhr.response), body);
                        }
                    } else if (ajaxUrl && ajaxUrl.includes('/cart/change.js')) {
                        setTimeout(() => {
                            if (document.querySelectorAll(["#cart-sidebar-checkout"]).length) {
                                document.querySelector(["#cart-sidebar-checkout"]).disabled = false;
                            }
                        }, 5000);
                    }
                }, false);
                // Flag to indicate that the event listener has been set up
                xhr.readystatechangeListener = true;
            }

            return xhr;
        }
    });

    useEffect(() => {
        if (window.fetch) {
            const nativeFetch = window.fetch;
            window.fetch = async function () {
                const response = await nativeFetch(...arguments);
                if (response && response.url.includes('/add.js') && arguments && arguments.length > 0) {
                    try {
                        const responceData = await response.json();
                        if (responceData) {
                            await changeCartData(responceData, 1)
                        }
                    } catch (error) {
                        console.log("error------>", error)
                    }
                }
                return response;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        pageurl === 'product-page' && (rewardPoints || parseInt(rewardPoints) === 0) ? <div>
            <span className="productView-info-name">Points:</span>
            <span className="productView-info-value">{rewardPoints}</span>
        </div> : <></>
    )
}

export default AaPharmacyFront