import React from 'react';
import { BridgeApp, config_variable } from './commonApi';
import { Button as AppButton, Redirect } from '@shopify/app-bridge/actions';
import { ApiCall } from './axios';

const convertToBoolean = (binary_value) => {
    if (binary_value === 0 || binary_value === '0') {
        return false;
    } else {
        return true;
    }
};

const convertToBinary = (boolean) => {
    if (boolean === true) {
        return 1;
    } else {
        return 0;
    }
};

function checkStrExistInArray(str, array) {
    for (let i = 0; i < array.length; i++) {
        if (array[i].includes(str)) {
            return true;
        } else {
            return false;
        }
    }
}

function getJsDate(date, format = 'DD-MM-YYYY') {
    const splitDate = date.split('-');
    switch (format) {
        case 'DD-MM-YYYY':
            return new Date(splitDate[2], Number(splitDate[1]) - 1, splitDate[0]);
        case 'MM-DD-YYYY':
            return new Date(splitDate[2], Number(splitDate[0]) - 1, splitDate[1]);
        case 'YYYY-MM-DD':
            return new Date(splitDate[0], Number(splitDate[1]) - 1, splitDate[2]);
        default:
            break;
    }
    return new Date();
}
function changeDateSeparator(date = '', existing_separator, replace_separator) {
    return date.replaceAll(existing_separator, replace_separator);
}

function getTime(date) {
    return new Date(date).getTime();
}

function getJsTime(jsDate) {
    return (jsDate).getTime();
}

function handlePageRefresh(event) {
    const e = event || window.event;
    // Cancel the event
    e.preventDefault();
    if (e) {
        e.returnValue = ''; // Legacy method for cross browser support
    }
    return ''; // Legacy method for cross browser support
}

const handleTitleBar = (lable, url) => {
    if (process.env?.MODE !== 'local' && !config_variable?.shop_url.includes('aapharmacy.com.my')) {
        let earnPointsBreadcrumb = AppButton.create(BridgeApp, { label: lable });
        earnPointsBreadcrumb.subscribe(AppButton.Action.CLICK, () => {
            BridgeApp.dispatch(Redirect.toApp({ path: url }));
        });

        return earnPointsBreadcrumb;
    } else {
        return {};
    }
}

// Function to set a cookie
function setCookie(name, value, expirationHours) {
    var expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + (expirationHours * 60 * 60 * 1000));
    var expires = "expires=" + (expirationHours ? expirationDate.toUTCString() : 0);
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

// Function to get the value of a cookie by name
function getCookie(name) {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        if (cookie.indexOf(name + "=") == 0) {
            return cookie.substring(name.length + 1, cookie.length);
        }
    }
    return null;
}

function convertDateFormat(isoDateString, type) {
    // Split the ISO 8601 string to extract date and time parts
    const [datePart, timePart] = isoDateString.split('T');
  
    // Extract date components
    const [year, month, day] = datePart.split('-');
    
    // Extract time components
    let [hour, minute] = timePart.split(':');
  
    // Format the time part to 12-hour format
    let formattedTime = '';
    if (hour < 12) {
      formattedTime = `${hour}:${minute} AM`;
    } else {
      formattedTime = `${hour - 12}:${minute} PM`;
    }

    if (type === 'time') {
        formattedTime = `${hour}:${minute}`;
    }
   
    // Format the date string
    const formattedDate = `${day}/${month}/${year} ${formattedTime}`;

    if (type === 'date') {
        return `${year}-${month}-${day}`;
    } else if (type === 'time') {
        return formattedTime;
    } else if (type === 'datetime') {
        return `${day}/${month}/${year} at ${formattedTime}`;
    } else {
        return formattedDate;
    }
  }

function waitForElement(selector, callback) {
    var interval = setInterval(function() {
        var element = document.querySelector(selector);
        if(element) {
            clearInterval(interval); // Clear the interval once the element is found
            callback(element); // Call the callback function with the found element
        }
    }, 100); // Check every 100 milliseconds
}

const htmlspecialchars_decode = (string = '', quoteStyle = 2) => {
    string = string || '';
    string = string.toString().replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    const OPTS = {
        ENT_NOQUOTES: 0,
        ENT_HTML_QUOTE_SINGLE: 1,
        ENT_HTML_QUOTE_DOUBLE: 2,
        ENT_COMPAT: 2,
        ENT_QUOTES: 3,
        ENT_IGNORE: 4,
    };
    if (typeof quoteStyle !== 'number') {
        quoteStyle = OPTS[quoteStyle];
    }
    if (quoteStyle && OPTS.ENT_HTML_QUOTE_SINGLE) {
        string = string.replace(/&#039;/g, "'");
    }
    if (quoteStyle !== 0) {
        string = string.replace(/&quot;/g, '"');
    }
    string = string.replace(/&amp;/g, '&');
    return string;
};

const createLogDB = async (access_token = '', data) => {
    const res = await ApiCall('POST', '/error_log_generate', data, { authentication: access_token });
    if (res.data.status === 'SUCCESS' && res.status === 200) {
        const logData = res.data.data;
        return logData;
    } else {
        return '';
    }
}

export { convertToBoolean, convertToBinary, checkStrExistInArray, setCookie, getCookie, getJsDate, getTime, getJsTime, handlePageRefresh, changeDateSeparator, convertDateFormat, waitForElement, htmlspecialchars_decode, createLogDB, handleTitleBar };