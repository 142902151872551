import React from 'react';
import { Router, routes } from './router/Router';
import { Frame } from '@shopify/polaris';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Spinner from './pages/Spinner'

const StyleTheme = React.lazy(() => import('./pages/Style'));

const StyleProvider = ({ children }) => {
  return (
    <>
      <React.Suspense fallback={<Spinner />}>
        <StyleTheme children={children} />
      </React.Suspense>
    </>
  )
}


function App() {

  return (
    <StyleProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Router />} >
            {routes.map((route, i) => (<Route exact={true} path={route.path} key={i} element={
              <Frame>
                {route.component}
              </Frame>} />))}
            <Route element={<Navigate to='/' replace />} />
          </Route>
        </Routes>
      </BrowserRouter >
    </StyleProvider>
  );
}

export default App;
