import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import Store from './redux';
import AaPharmacyFront from './pages/AaPharmacyFront';
import FrontRewardCart from './pages/FrontRewardCart';
import FrontVoucherList from './pages/FrontVoucherList';
import HeaderRewardPoints from './pages/HeaderRewardPoints';
import ThankyouPage from './pages/ThankyouPage';
import { AppProvider, Frame } from '@shopify/polaris';
import Spinner from '../src/pages/Spinner'
import enTranslations from '@shopify/polaris/locales/en.json';
import RewardWidget from './pages/widget/RewardWidget';
const StyleTheme = React.lazy(() => import('./pages/Bootstrap'));

const StyleFile = ({ children }) => {
  return (
    <>
      <React.Suspense fallback={<Spinner />}>
        <StyleTheme children={children} />
      </React.Suspense>
    </>
  )
}

const enableShopifyButtons = () => {
  window.addEventListener("load", (event) => {
    if (document.querySelectorAll('.reward_addto_cart:disabled').length) {
        document.querySelectorAll('.reward_addto_cart:disabled').forEach((item) => {
            item.removeAttribute('disabled');
        })
    }
  });

  window.addEventListener("scroll", (event) => {
    if (document.querySelectorAll('.reward_addto_cart:disabled').length) {
        document.querySelectorAll('.reward_addto_cart:disabled').forEach((item) => {
            item.removeAttribute('disabled');
        })
    }
  });
}

try {
  if (window?.Shopify?.shop === 'aapharmacy.myshopify.com' && window?.Shopify?.theme?.id) {
    window.enableRewardApp = true;
    if (window?.__st?.pageurl.includes('aapharmacy.com.my/')) {
      const script = document.querySelector('.header-points-voucher-container');
      if (script) {
        let root = ReactDOM.createRoot(script);
        root.render(
          window?.__st?.cid ? <HeaderRewardPoints /> : <></>
        );
      }
      const widgetPopup = document.querySelector('.reward-widget-popup');
      if (widgetPopup) {
        let root = ReactDOM.createRoot(widgetPopup);
        root.render(
          <StyleFile>
            <RewardWidget />
          </StyleFile>
        );
      }
    }
    if (window?.__st?.pageurl?.includes('/products/')) {
      const script = document.querySelector('.product-reward-point-show');
      if (script) {
        let root = ReactDOM.createRoot(script);
        root.render(
          <AaPharmacyFront pageurl="product-page" />
        );
      }
    } else if (window?.__st?.pageurl?.includes('/cart')) {
      const scriptElement = document.createElement('script');
      const script = document.querySelector('.total-reward-points-cart-page') || document.querySelector('.total-reward-points-cart-empty-page');
      if (script) {
        script.appendChild(scriptElement);
        let root = ReactDOM.createRoot(scriptElement);
        root.render(
          <FrontRewardCart />
        );
      }
      enableShopifyButtons();
    } else if (window?.__st?.pageurl?.includes('/view-all-voucher')) {
      const script = document.querySelector('#voucher-page-content');
      if (script) {
        let root = ReactDOM.createRoot(script);
        root.render(
          <AppProvider i18n={enTranslations}>
            <Frame>
              <FrontVoucherList />
            </Frame>
          </AppProvider>
        );
      }
    } else if (window?.__st?.pageurl?.includes('/thank_you') || window.__st?.pageurl?.includes('/orders')) {
      const script = document.querySelector('tfoot.total-line-table__footer');
      const newElement = document.createElement('tr');
      newElement.classList.add('total-line')
      if (script) {
        script.insertBefore(newElement, script.firstChild);
        let root = ReactDOM.createRoot(newElement);
        root.render(
          <>
            <ThankyouPage />
          </>
        );
      }
    } else if (window?.__st?.pageurl.includes('aapharmacy.com.my/')) {
      const divElement = document.createElement('div');
      divElement.id = 'reward-point-content-element'
      document.body.appendChild(divElement);
      const root = ReactDOM.createRoot(divElement);
      root.render(
        <AaPharmacyFront pageurl="All-Pages" />
      );
      enableShopifyButtons();
    } 
  } else {
    try {
      if (document.getElementById('root')) {
        let root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
          <Provider store={Store}>
              <App />
          </Provider>
        );
      }
    } catch (error) {
      console.log('error', error);
    }
  }
} catch (error) {
  console.error('error', error);
}